import { API_SERVER } from './apis'


export const API_LOGIN          = API_SERVER + '/login'


export const API_MENU_LOAD      = API_SERVER + '/menuLoad'
export const API_PROD_LIST      = API_SERVER + '/prodList'
export const API_PROD_LIST_BEST = API_SERVER + '/procBestList'
export const API_PROD_SEARCH    = API_SERVER + '/searchByName'



















